import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FaBook, FaEdit } from 'react-icons/fa';
import { useWindowSize } from "react-use-size";
import { TodoList } from './TodoList';

export const ModuleCard = (params) => {
  const { courseId, moduleId, moduleItem, innerRef, hasEditPermissions } = params

  // const thumbnailUrl = "/campus/gates.jpg"

  return (
    <div id={moduleId} ref={innerRef} className='mb-3 mt-3' aria-label={`Module ${moduleItem.title}`}>
      <ModuleHeader {...params} />
      <Card.Body >

        <TodoList todoItemList={moduleItem.items} />
      </Card.Body>
    </div>

  );
}

const ModuleHeader = (params) => {

  const { moduleItem } = params
  return <>

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

      <ModuleTitle title={moduleItem.title} />
      {/* <OverviewButton {...params}/> */}
    </div>
  </>
}

const OverviewButton = (params) => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useWindowSize().width < 768
  const { courseId, moduleId, moduleItem, hasEditPermissions } = params
  return <>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
      {isMobile ?
        <button
          className='btn btn-light'
          onClick={() => setShowModal(true)}
          style={{ width: '45px', height: '45px', padding: '0' }}
        >
          <FaBook />
        </button>
        :
        <button
          className='btn btn-light'
          onClick={() => setShowModal(true)}
        >
          <FaBook /> &nbsp;Overview
        </button>
      }
    </div>
    <OverviewModal
      courseId={courseId}
      moduleId={moduleId}
      moduleItem={moduleItem}
      show={showModal}
      onHide={() => setShowModal(false)}
      hasEditPermissions={hasEditPermissions}
    />
  </>
}

const ModuleTitle = ({ title }) => {
  return (
    <div style={{
      backgroundColor: 'rgba(255, 255, 255, 0.85)', // white with transparency
      borderRadius: '5px', // rounded corners
      padding: '5px', // padding between the edges and the text
    }}>
      <span style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '0px' }}>
        {title}
      </span>
    </div>
  )
}


const OverviewModal = ({ courseId, moduleId, moduleItem, show, onHide, hasEditPermissions }) => {
  const firebaseDocPath = `roadmap/${courseId}/descriptions/${moduleId}`
  const [isEditing, setIsEditing] = useState(false)

  return <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {moduleItem.title} Overview
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {/* <TipTap
        firebaseDocPath={firebaseDocPath}
        editable={isEditing}
        collaborative={isEditing}
        buttonBar={HandoutButtonBar}
        showLoadingSkeleton={true}
      />
      {hasEditPermissions && <button className="btn btn-light" onClick={() => setIsEditing(!isEditing)}>
        {isEditing ? "Done" : <FaEdit />}
      </button>} */}
    </Modal.Body>
  </Modal>
}

export const EndOfCourseCard = () => {
  return <Card>
    <Card.Img aria-label="A picture of jacaranda trees on Stanford campus" src='campus/jacaranda.jpg'></Card.Img>
    <Card.ImgOverlay style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card.Title style={{ backgroundColor: '#ffffffdd', padding: 10, borderRadius: 5 }}>
        That's all for now!
      </Card.Title>

    </Card.ImgOverlay>
  </Card>
}