import React, { useState, useContext } from "react";
import { Form, Button } from 'react-bootstrap';
import { EmailSentNotice } from "./EmailSentNotice";
import { LoginContext, LoginScreen } from "./LoginContext";
import { PrimaryLogInButton, SignInButtonFlat } from "./SignInButton";

export const EmailLinkform = ({
  auth,
  emailHandler,
  title,
  body,
  type,
  actionCodeSettings = {},
}) => {
  const { setLoginState } = useContext(LoginContext)
  
    
  const [magicLinkEmail, setMagicLinkEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setIsLoading] = useState(false)
  const [sent, setSent] = useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    setIsLoading(false)
    setSent(false)
    try {
      setIsLoading(true)
      await emailHandler(auth, magicLinkEmail, actionCodeSettings)
      setIsLoading(false)
      setSent(true)
    } catch (error) {
      setError(error.message)
    }
  }

  return (
    <div className="d-flex flex-column align-items-center">
      {!sent ? <><h2>{title}</h2>
        <p className="mt-2 mb-2 px-2 text-center">{body}</p>
        <Form onSubmit={(e) => {
          handleSubmit(e)
        }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              style={{ width: 300 }}
              type="email"
              placeholder="Enter email"
              onChange={(e) => setMagicLinkEmail(e.target.value)}
              isInvalid={error.length > 0}
              aria-label="Enter email"
            />
            <Form.Control.Feedback type='invalid'>
              Error sending email.
            </Form.Control.Feedback>
          </Form.Group>
          <PrimaryLogInButton
            variant="primary"
            type="submit"
            style={{ width: "100%" }}
            className="mb-1"
            disabled={loading}>
            {loading ? 'Loading…' : 'Send Email'}
          </PrimaryLogInButton>
          <SignInButtonFlat
            variant="light"
            onClick={() => setLoginState(LoginScreen.INIT_LANDING)}
            style={{ width: "100%" }}
          >
            Go Back
          </SignInButtonFlat>
        </Form></> :
        <EmailSentNotice emailHandler={emailHandler} handlerArgs={[auth, magicLinkEmail, actionCodeSettings]} type={type} />

      }
    </div>

  )
}