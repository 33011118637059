
import { Link, useNavigate, useParams } from "react-router-dom"
import { iconActiveBackgroundColor, iconCompleteColor, iconIncompleteColor, todoCircleDiameter, todoConnectorWidth } from "./RoadmapConstants"
import { useContext, useEffect, useState } from "react"
import { RoadmapContext } from "contexts/RoadmapContext"
import { TodoIcon } from "./TodoIcon"
import { ROADMAP } from "./RoadmapSchema"


export const TodoList = ({ todoItemList }) => {
  const completionMap = {}

  return <div style={{ position: 'relative' }}>
    <TodoConnector todoList={todoItemList} completionMap={completionMap} />
    {todoItemList.map((todo, index) => {
      return <TodoItem key={todo.id} todo={todo} isComplete={completionMap[todo.id]} />
    })}
  </div>
}

// Don't touch
const TodoConnector = ({ todoList, completionMap }) => {
  const diameter = todoCircleDiameter
  const width = todoConnectorWidth
  const radius = diameter / 2
  const gapX = 10
  const gapY = 15

  const left = radius + gapX - width / 2
  const top = radius + gapY
  const height = (diameter + gapY)

  const segments = []

  // Add a segment for each todo in between"
  for (let i = 0; i < todoList.length - 1; i++) {
    const todo = todoList[i]
    const nextTodo = todoList[i + 1]
    const isComplete = completionMap[todo.id]
    const nextIsComplete = completionMap[nextTodo.id]
    const isLast = i === todoList.length - 2

    let segment = (
      <div key={i} style={{
        position: 'absolute',
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor: isComplete && nextIsComplete ? iconCompleteColor : iconIncompleteColor,
        marginLeft: `${left}px`,
        marginTop: `${top + i * height}px`,
        zIndex: '1'
      }}></div>
    )
    segments.push(segment)

  }



  return (
    <>
      {segments}
    </>
  )
}


const TodoItem = ({ todo, isComplete }) => {
  console.log('todo', todo)

  return <div style={todoContainerStyle}>
    <TodoCircle iconData={todo} isComplete={isComplete} />
    <TodoLabel iconData={todo} />
  </div>
}


const TodoCircle = ({ iconData, isComplete }) => {
  const { courseId } = useParams();

  const style = {
    ...todoCircleStyle(isComplete)
    // borderRadius: isActive ? '50%': '20px',
  }
  return <Link to={makeLink(iconData, courseId)} className="btn btn-primary btn-sm " style={style}>
    <TodoIcon icon={iconData.itemType} isComplete={isComplete} />
  </Link>
}



const TodoLabel = ({ iconData }) => {
  const { courseId } = useParams();
  // const { setSelfCheckCompleted } = useContext(CompletionContext);
  const setSelfCheckCompleted = () => { }
  const navigate = useNavigate()

  const currentUrl = window.location.href; // Gets the current URL
  const subPage = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

  const from = `/${courseId}/${subPage}`

  const onSelfCheckClick = async () => {
    // await setSelfCheckCompleted(iconData.completionId || iconData.urlId)
    navigate(makeLink(iconData, courseId))
  }


  if (iconData.itemType === 'link') {
    return <>
      <a onClick={onSelfCheckClick}>
        <div onClick={onSelfCheckClick} style={todoLabelStyle}>
          {iconData.title}
        </div>
      </a>
    </>
  }

  return (
    <>
      <a onClick={() => {
        navigate(makeLink(iconData, courseId))
      }}>
        <div style={todoLabelStyle}>
          {iconData.title}
        </div>
      </a>

    </>
  )
}

const makeLink = (todoItem, courseId) => {
  switch (todoItem.itemType) {
    case ROADMAP.LESSON.key:
      return `/${courseId}/learn/${todoItem.urlId}`
    case ROADMAP.ASSIGNMENT.key:
      return `/${courseId}/ide/a/${todoItem.urlId}`
    case ROADMAP.UNTIED_LINK.key:
    case ROADMAP.COMPLETION_LINK.key:
      return `${todoItem.urlId}`;
    case ROADMAP.SECTION.key:
      return `/${courseId}/section`
  }
  return "https://codeinplace.stanford.edu/"
}













const todoContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '10px',
  marginTop: '15px',
  zIndex: 3
}

const todoLabelStyle = {
  marginLeft: '10px',
  fontSize: '18px',
  color: "rgba(70, 120, 178, 1)",
  cursor: 'pointer'
}

const todoCircleStyle = (isComplete) => {
  return {
    width: `${todoCircleDiameter}px`,
    height: `${todoCircleDiameter}px`,
    borderRadius: '50%',
    backgroundColor: isComplete ? iconCompleteColor : iconIncompleteColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    zIndex: 2
  }
}

export const todoOuter = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginBottom: '50px'
}
