
import { Button, Alert } from 'react-bootstrap';
import React, {useContext, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { LoginContext, LoginScreen } from './LoginContext';
import { LogInLinkButton } from './SignInButton';


export const EmailSentNotice = ({
    emailHandler,
    handlerArgs,
    type
  }) => {
  const {setLoginState} = useContext(LoginContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleResend = async () => {
    try {
      setError('')
      setLoading(true)
      await emailHandler(...handlerArgs)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }
      return (
        <div className = "d-flex flex-column align-items-center px-2">
          <h3>Email sent!</h3>
          <p className='text-center'>Please check your email for further instructions. If you don't see the email, check your spam folder. </p>
          <div className = "d-flex flex-row justify-content-center">
          <LogInLinkButton 
            variant="link" 
            onClick = {()=> setLoginState(LoginScreen.INIT_LANDING)} 
            >
              Go back
            </LogInLinkButton>
            <LogInLinkButton variant = "link" onClick = {handleResend}>
            {loading ? 'Loading' : 'Resend email'}
            </LogInLinkButton>
          </div>
          {error && <p style = {{color: "red"}}>Error sending email. Please try again.</p>}
          
        </div>
      )
    }