import { FcHome, FcIdea } from "react-icons/fc";
import { ReactComponent as PythonIcon } from "icons/python.svg";
import { ReactComponent as BlackboardIcon } from "icons/blackboard.svg";
import { useLocation } from "react-router-dom";
import { ReactComponent as ChatIcon } from "icons/chat.svg";
import { ReactComponent as BalloonIcon } from "icons/balloon.svg";
import { ReactComponent as UniversityIcon } from "icons/university.svg";
import { ReactComponent as Telescope } from "icons/telescope.svg";
import { ReactComponent as VideoCall } from "icons/videoCall.svg";
import { ReactComponent as Tea } from "icons/tea.svg";
import { ReactComponent as Campfire } from "icons/campfire.svg";
import { FaWrench } from "react-icons/fa";

// Many icons come from https://icons8.com/icons/

export const getIcon = (subpage) => {
  switch (subpage) {
    case "home": return <FcHome style={ICON_STYLE} />;
    case "code-pages": return <PythonIcon style={ICON_STYLE} />;
    case "learn": return <Telescope style={ICON_STYLE} />;
    case "section": return <VideoCall style={ICON_STYLE} />;
    case "forums": return <ChatIcon style={ICON_STYLE} />;
    case "stories": return <BalloonIcon style={ICON_STYLE} />;
    case "events": return <Campfire style={ICON_STYLE} />;
    case "aboutcourse": return <UniversityIcon style={ICON_STYLE} />;
    case "training": return <BlackboardIcon style={ICON_STYLE} />;
    case "assnEditor": return <FaWrench style={ICON_STYLE} />;
    case "teacherslounge": return <Tea style={ICON_STYLE} />;
    case "lessoneditor": return <FaWrench style={ICON_STYLE} />;
    case "chatgpt": return <FcIdea style={ICON_STYLE} />;
    default: return <FcHome style={ICON_STYLE} />;
  }
};

export const getTitle = (subpage) => {
  switch (subpage) {
    case "learn": return "Lessons";
    case "section": return "Section";
    // home submenu items:
    case "home": return "Home";
    case "adminhome": return "Admin";
    case "teachinghome": return "Teacher";
    case "studenthome": return "Student";
    // forum submenu items:
    case "forums": return "Forums";
    case "forum": return "Main";
    case "sectionforum": return "Section"
    case "teacherslounge": return "Teachers";
    // code submenu items:
    case "code-pages": return "Code";
    case "code": return "Challenges";
    case "practice": return "Examples"
    case "create": return "Your Own"

    // ---
    case "stories": return "Stories";
    case "events": return "Events";
    case "aboutcourse": return "About";
    case "training": return "Training";
    case "assnEditor": return "Assn Editor";
    // case "teacherslounge": return "Lounge";
    case "lessoneditor": return "Lesson Edit";
    case "chatgpt": return "ChatGPT";
    default: return "";
  }
};

const ICON_STYLE = {
  width: "30px",
  height: "30px",
};

export const isActive = (subpage) => {
  return false
  // TODO
  // returns whether or not user is currently on the given subpage
  // const location = useLocation();
  // const currSubpage = location.pathname.split("/")[2];
  // return currSubpage == subpage;
}