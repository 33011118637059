import React, {createContext, useState } from "react";

export const LoginContext = createContext(null);

export const LoginProvider = ({children}) => {

  const [loginState, setLoginState] = useState("initialLanding")

  return (
    <LoginContext.Provider
      value={{
        loginState, setLoginState
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export enum LoginScreen {
  INIT_LANDING = "initialLanding",
  UNVERIFIED = "verificationNeeded",
  LOGIN_FORM = "loginForm",
  FORGOT_PASWORD = "forgotPassword",
  EMAIL_LINK = "emailLink"
}