import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Loading } from '../loading/Loading.js';
import { useParams } from 'react-router-dom';
import { LoginPublic } from './LoginPublic.js'
import { useUser } from 'contexts/UserContext.js';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

export const Login = () => {
  // you can assume that the auth is done loading

  // get the url parameters
  const { qtrId } = useParams()
  if (!qtrId || qtrId === 'public') {
    return LoginPublic()
  }
  return LoginStanford()
};

const LoginStanford = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <StyledFirebaseAuth
        uiConfig={{
          signInOptions: [
            {
              provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              customParameters: {
                hd: 'stanford.edu',
                auth_type: 'reauthenticate',
                prompt: 'select_account'
              }
            }
          ],
          signInFlow: 'redirect',
          immediateFederatedRedirect: true
        }}
        firebaseAuth={firebase.auth()}
      />
    </div>
  );
}