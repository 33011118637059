import { RoadmapContext } from 'contexts/RoadmapContext';
import { useCourseId } from 'hooks/UseUrlParams';
import { EndOfCourseCard, ModuleCard } from 'public/roadmap/ModuleCard';
import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';

export const Roadmap = () => {

  // this is all the data of the roadmap
  const { roadmapItemMap } = useContext(RoadmapContext);
  const filteredRoadmap = Object.keys(roadmapItemMap);

  const moduleRefs = useRef({});

  // create a ref for each of the modules in the roadmap
  // moduleRefs[moduleId] will be a ref to the module with id moduleId
  const containerRef = useRef(null);

  // this controls the icon which is visualized, it should not be used to
  // move the view
  const [selectedModuleId, setSelectedModuleId] = useState("");

  // check whether user has perms to edit roadmap announcement / overview tiptaps
  
  const courseId= useCourseId()
  const hasEditPermissions = false


  useEffect(() => {

    const container = containerRef.current
    if(!container) return


    container.addEventListener("scroll", findIntersectedDiv);
    return () => {
      if(!container) return
      container.removeEventListener("scroll", findIntersectedDiv);
    };
  }, [containerRef.current]);

  function findIntersectedDiv() {
    const container = containerRef.current
    const children = container.children
    const intersectionPoint = container.offsetHeight * 0.2 + container.scrollTop;
  
    for (let child of children) {
      const childTop = child.offsetTop;
      const childBottom = childTop + child.offsetHeight;
  
      if (intersectionPoint >= childTop && intersectionPoint <= childBottom) {
        setSelectedModuleId(child.id);
      }
    }
  }

  const scrollToCard = (moduleId) => {
    const headerHeight = 110
    setSelectedModuleId(moduleId)
    const container = containerRef.current
    const moduleCard = moduleRefs.current[moduleId].current // TODO @miranda: bug where on first click of icon in treasure map on teaching home, moduleCard is null
    if(!container || !moduleCard) return
    container.scroll({
      top: moduleCard.offsetTop - headerHeight,
      behavior: "smooth"
    })
  }


  /**
   * @description Allow scrolling from outside of main column
   */
  useEffect(() => {
    const externalDiv = document.getElementById("splash-container-broad")
    if(!externalDiv) return
    const childDiv = containerRef.current
    if(!childDiv) return


    const onWheel = (e) => {
      childDiv.scrollTop += e.deltaY;
      e.preventDefault(); // Prevent the default scrolling behavior
    }


    externalDiv.addEventListener('wheel', onWheel);

    return () => {
      if(!externalDiv) return
      externalDiv.removeEventListener('wheel', onWheel);
    }

  }, [containerRef.current])

  return <div>
    <div 
      className='module-cards-container w-100' 
      style={{ scrollbarWidth: "none" }} 
      ref={containerRef}
      id="todo-container"
    >
      {
      filteredRoadmap.map((moduleId) => {
        // Create a new ref if it does not yet exist
        if (!moduleRefs.current[moduleId]) {
          moduleRefs.current[moduleId] = React.createRef();
        }

        return (
          <ModuleCard
            key={moduleId}
            courseId={courseId}
            moduleId={moduleId}
            moduleItem={roadmapItemMap[moduleId]}
            innerRef={moduleRefs.current[moduleId]}
            hasEditPermissions={hasEditPermissions}
          />
        );
      })
    }

      {/* <EndOfCourseCard/> */}
    </div>
  </div>
}





const filterRoadmap = (roadmapItemMap) => {
  return Object.keys(roadmapItemMap)
    .sort((a, b) => {
      const aDate = new Date(roadmapItemMap[a]["startDate"])
      const bDate = new Date(roadmapItemMap[b]["startDate"])
      // NOTE changed the order here -- oldest -> newest now, unlike in TodoListSection
      if (aDate > bDate) {
        return 1
      } else if (aDate < bDate) {
        return -1
      } else {
        return 0
      }
    })
}