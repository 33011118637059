
import {
  Sidebar,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import { menuItemStyles, onlineMenuStyles } from "./menuItemStyles";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { useContext } from "react";

import { isActive, getIcon, getTitle } from "./courseSidebarUtils"
import { HomeSubMenu, CodeSubMenu, ForumsSubMenu } from "./SubMenus";


export const CourseSidebar = () => {


  return (
    <div>
      <Sidebar
        breakPoint="sm"
        backgroundColor="white"
        width="190px"
        collapsedWidth="70px"
        rootStyles={{
          borderRight: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Menu
          menuItemStyles={{
            button: {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              [`&.active`]: {
                backgroundColor: '#13395e',
                color: '#b6c8d9',
              },
            },
          }}
        >
          <CourseMenuItem
            title="Home"
            icon={getIcon("home")}
            subpage=""
          />
          <OutboundMenuItem
            title="Textbook"
            icon={getIcon("learn")}
            to="https://chrispiech.github.io/probabilityForComputerScientists/en/"
          />
          <CourseMenuItem
            title="Forum"
            icon={getIcon("forums")}
            subpage="textbook"
          />
          <CourseMenuItem
            title="About"
            icon={getIcon("aboutcourse")}
            subpage="aboutcourse"
          />
        </Menu>
      </Sidebar>
    </div>
  );
};

export const CourseMenuItem = ({ title, icon, subpage, isLinkActive = true }) => {
  const active = isActive(subpage)

  return (
    <MenuItem
      component={isLinkActive ? <Link to={`/public/${subpage}`} /> : null}
      active={active}
      id={`nav-${subpage}`}
      icon={icon}
    >{title}</MenuItem>
  );
};

export const OutboundMenuItem = ({ title, icon, to }) => {
  return (
    <MenuItem
    icon={icon}
      component={<Link 
        target="_blank"
        to={to} 
      />}
      id={`nav-${title}`}
    >{title}</MenuItem>
  );
};


export const SidebarFooter = ({ children, collapsed, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      (
      <div {...rest}>
        <div style={{ marginBottom: "12px" }}></div>
        <Typography fontWeight={600}>Pro Sidebar</Typography>
        <Typography
          variant="caption"
          style={{ letterSpacing: 1, opacity: 0.7 }}
        >
          V 23
        </Typography>
        <div style={{ marginTop: "16px" }}>
          <button>
            <Typography variant="caption" color="#607489" fontWeight={600}>
              View code
            </Typography>
          </button>
        </div>
      </div>
      )
    </div>
  );
};

export const Divider = () => {
  return <hr style={{ margin: "10px" }} />;
};

const Typography = ({ children, ...rest }) => {
  return <span {...rest}>{children}</span>;
};
